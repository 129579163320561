export interface AssetFile {
  type: AssetFileType;
  url: string;
}

export interface Asset {
  id: string;
  files: AssetFile[];
  width: number | undefined;
  height: number | undefined;
  assetProviderUid: string;
  mimeType: string;
  metadata?: Array<{ key: 'fillColors' | 'quantizedColors'; value: string[] }>;
}

export enum AssetFileType {
  FILE_ORIGINAL = 'file_original',
  PREVIEW_DEFAULT = 'preview_default',
  PREVIEW_THUMBNAIL = 'preview_thumbnail',
  PREVIEW_QUANTIZED = 'preview_quantized',
}
