import { DesignData, Dimensions } from 'editor/src/store/design/types';
import { AdditionalInfo } from 'editor/src/store/variants/types';

export function getDesignKeyFromDesign(designData: DesignData) {
  const dimensions = designData.related_dimensions || designData.dimensions;
  const pageCount = designData.page_count_limit ? designData.page_count ?? '' : '';
  return `${designData.product_uid}-${getDimensionsKeyPart(dimensions)}-${pageCount}`;
}

function getDesignKey(productUid: string, params: AdditionalInfo) {
  const dimensions = params.relatedDimensions || params.dimensions;
  return `${productUid}-${getDimensionsKeyPart(dimensions)}-${params.pageCount ?? ''}`;
}

function getDimensionsKeyPart(dimensions: Dimensions | undefined) {
  return dimensions ? `${dimensions.width}x${dimensions.height}` : '';
}

export default getDesignKey;
