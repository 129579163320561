import { ContentAddress, DashPattern, DropShadow, LeaningShadow, Stroke } from 'editor/src/store/design/types';

import type { UploaderConfig } from 'shared/uploader';

export const enum ManipulationMode {
  perspectiveTransform = 'perspectiveTransform',
}

export interface EditorState {
  settings: Settings;
  defaultSettings: Settings;
  currentSpreadIndex: number;
  selectedElementUuids: number[];
  cropModeElementUuid: number | undefined;
  clipboardElementUuid: number | undefined;
  elementManipulationMode: { uuid: number; mode: ManipulationMode } | undefined;
  focusedMediaElementUuid: number | undefined;
  pluginPreviewMode:
    | {
        elementUuid: number;
        pluginName: string;
        assetId: string;
        url: string;
      }
    | undefined;
  unlockSizeRatioElementUuids: number[];
  disabledSpreads: DisabledSpread[];
  imagePatternScaleBases: Array<{
    value: number;
    dimension: 'width' | 'height';
    label: string;
  }>;
  trackDesignChanges: boolean;
  textEditMode: boolean;
  zoomMode: boolean;
  focusedContentAddress: ContentAddress | undefined;
  currentStyles: {
    line: { dashPattern: DashPattern };
    rectangle: { dashPattern: DashPattern };
    text: { shadow: DropShadow; stroke: Stroke; fontFamily: string; curve: number };
    image: { shadow: DropShadow | LeaningShadow };
  };
  uploaderConfig: UploaderConfig | undefined;
  dragState: 'local' | 'system' | undefined;
  isLoading: boolean;
  mobileMenu: {
    isOpen: boolean;
  };
}

export interface DisabledSpread {
  spreadIndex: number;
  information: string;
}

export interface BaseDraggableItem {
  itemType: DraggableItemType;
  itemThumbnail?: string;
  sourceId?: string;
  dropCanvasCoords?: any;
  dropToElementUuid?: number;
  dimensions?: { width: number; height: number };
}

export interface DraggableSingleItem extends BaseDraggableItem {
  itemId: string;
  isMultipleItems: false;
}

export interface DraggableMultipleItems extends BaseDraggableItem {
  itemIds: string[];
  isMultipleItems: true;
}

export type DraggableItem = DraggableSingleItem | DraggableMultipleItems;

export enum DraggableItemType {
  none = 'none',
  image = 'image',
  assetImage = 'assetImage',
  addon = 'addon',
}
export type Unit = 'mm' | 'cm' | 'inch';

export enum SettingsProperty {
  showGrid = 'showGrid',
  showContentAreas = 'showContentAreas',
  showDescriptions = 'showDescriptions',
  showGuides = 'showGuides',
  showBleeds = 'showBleeds',
  showWrapsAndJoints = 'showWrapsAndJoints',
  units = 'units',
  showRulers = 'showRulers',
  showRollerStripes = 'showRollerStripes',
  showPerspectiveLines = 'showPerspectiveLines',
}
export interface Settings {
  [SettingsProperty.showGrid]: boolean;
  [SettingsProperty.showContentAreas]: boolean;
  [SettingsProperty.showDescriptions]: boolean;
  [SettingsProperty.showGuides]: boolean;
  [SettingsProperty.showBleeds]: boolean;
  [SettingsProperty.showWrapsAndJoints]: boolean;
  [SettingsProperty.units]: Unit;
  [SettingsProperty.showRulers]: boolean;
  [SettingsProperty.showRollerStripes]: boolean;
  [SettingsProperty.showPerspectiveLines]: boolean;
}
