import { MediaImage } from 'editor/src/store/design/types';
import { ImageLayoutFrameArea, LayoutFrame } from 'editor/src/store/editorModules/layouts/types';

import { elementUuids } from 'editor/src/util/uuids';

const createImageMediaElement = (
  frame: LayoutFrame,
  frameArea: ImageLayoutFrameArea,
  defaultPersonalizationLock: boolean,
  elementName: string,
): MediaImage => ({
  type: 'image',
  group: '',
  name: elementName,
  x: frame.x,
  y: frame.y,
  width: frame.width,
  height: frame.height,
  r: 0,
  url: '',
  px: 0,
  py: 0,
  pw: frame.width,
  ph: frame.height,
  pr: 0,
  uuid: elementUuids.generate(),
  imageId: '',
  locked: true,
  static: frameArea.static || undefined,
  createdByLayout: true,
  personalizationLocked: defaultPersonalizationLock || undefined,
  linkId: frameArea.linkId,
  layoutFrameId: frame.id,
  sourceFrame: frameArea.saveSourceFrame
    ? {
        width: frame.width,
        height: frame.height,
      }
    : undefined,
  shadow: frameArea.shadow,
  role: frameArea.role,
});

export default createImageMediaElement;
