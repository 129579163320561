import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import useDragging from './useDragging';

import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  addBackdrop: boolean;
  isHidden: boolean;
  close: () => void;
  maxHeight?: number;
}

function MobileMenu({ children, isOpen, addBackdrop, isHidden, close, maxHeight }: Props) {
  const isVisible = useRef(false);
  const firstMount = useRef(false);
  const [changing, setChanging] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!firstMount.current) {
      firstMount.current = true;
      return undefined;
    }

    isVisible.current = isOpen;
    setChanging(true);
    const handle = window.setTimeout(() => {
      setChanging(false);
    }, 150);
    return () => window.clearTimeout(handle);
  }, [isOpen]);

  const { dragging, onHandleDown } = useDragging(menuRef, close);

  if (!isOpen && !changing && !isVisible.current) {
    return null;
  }

  return (
    <div
      className={cn(styles.MobileMenu, 'mobileMenu', {
        [styles.notShown]: !isVisible.current,
        [styles.hidden]: isHidden,
      })}
    >
      {addBackdrop ? (
        <div className={cn(styles.backdrop, 'cy-backdrop')} onClick={close} />
      ) : (
        <div className={styles.back} onClick={close} />
      )}
      <div className={cn(styles.menu, { [styles.dragging]: dragging })} ref={menuRef}>
        <div className={styles.menuHandleZone} onPointerDown={onHandleDown}>
          <div className={styles.menuHandle} />
        </div>
        <div className={styles.menuContent} style={{ height: maxHeight }}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
