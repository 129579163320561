import { PayloadAction } from '@reduxjs/toolkit';

import { DesignData, DesignState } from 'editor/src/store/design/types';

import { PluginName } from '../../plugins/types';

const setDesignDataReducer = (state: DesignState, action: PayloadAction<DesignData | undefined>) => {
  state.designData = action.payload;
  state.globalPlugins =
    action.payload?.plugins?.filter(
      (plugin) => plugin === PluginName.AIPersonalization || plugin === PluginName.shopifyPersonalization,
    ) || [];
};

export default setDesignDataReducer;
