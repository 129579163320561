import { createSlice } from '@reduxjs/toolkit';

import { DeviceType } from 'editor/src/store/app/types';

import environment from 'editor/src/environment';
import getCustomerDeviceType from 'editor/src/util/getCustomerDeviceType';

import selectPreviousTabReducer from './reducer/selectPreviousTabReducer';
import setBaseTabsReducer from './reducer/setBaseTabsReducer';
import setPluginTabsReducer from './reducer/setPluginTabsReducer';
import setSidebarActiveTabReducer from './reducer/setSidebarActiveTabReducer';
import setSidebarVisibleReducer from './reducer/setSidebarVisibleReducer';
import updateSidebarTabsReducer from './reducer/updateSidebarTabsReducer';
import { SidebarState, TAB_NAMES } from './types';

export const sidebarInitialState: SidebarState = {
  isVisible: getCustomerDeviceType() === DeviceType.Desktop,
  activeTabIndex: -1,
  lastNonSystemTabIndex: 0,
  baseTabs: [
    TAB_NAMES.LAYERS,
    TAB_NAMES.GALLERY,
    TAB_NAMES.ADDONS,
    TAB_NAMES.LAYOUTS,
    TAB_NAMES.SETTINGS,
    ...(environment.enforceTabs || []),
  ],
  requestedBaseTabs: [],
  pluginTabs: [],
  tabs: [],
};

const slice = createSlice({
  name: 'sidebar',
  initialState: sidebarInitialState,
  reducers: {
    updateSidebarTabsAction: updateSidebarTabsReducer,
    setSidebarActiveTabAction: setSidebarActiveTabReducer,
    setSidebarVisibleAction: setSidebarVisibleReducer,
    selectPreviousTabAction: selectPreviousTabReducer,
    setBaseTabsAction: setBaseTabsReducer,
    setPluginTabsAction: setPluginTabsReducer,
  },
});

const { actions, reducer } = slice;

export const {
  updateSidebarTabsAction,
  setSidebarActiveTabAction,
  setSidebarVisibleAction,
  selectPreviousTabAction,
  setBaseTabsAction,
  setPluginTabsAction,
} = actions;

export default reducer;
