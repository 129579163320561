import { Tuple, combineReducers, configureStore } from '@reduxjs/toolkit';
import { reducer as toastrReducer } from 'react-redux-toastr';

import appReducer from 'editor/src/store/app/slice';
import batchedSubscribeEnhancer from 'editor/src/store/batchedSubscribeEnhancer';
import canvasReducer from 'editor/src/store/canvas/slice';
import collectionsReducer from 'editor/src/store/collections/slice';
import designReducer from 'editor/src/store/design/slice';
import editorReducer from 'editor/src/store/editor/slice';
import editorModulesReducer from 'editor/src/store/editorModules';
import rightbarReducer from 'editor/src/store/editorModules/rightbar/slice';
import fontsReducer from 'editor/src/store/fonts/slice';
import galleryReducer from 'editor/src/store/gallery/slice';
import gridDesignsReducer from 'editor/src/store/gridDesigns/slice';
import hostSettingsReducer from 'editor/src/store/hostSettings/slice';
import mockupReducer from 'editor/src/store/mockup/slice';
import pluginsReducer from 'editor/src/store/plugins/slice';

import createI18n from 'editor/src/createI18n';
import { EnvEnum } from 'editor/src/environment';

import demoAppReducer from './demoApp/slice';
import draftsReducer from './drafts/slice';
import { variantStoragelistenerMiddleware } from './drafts/utils/variantStorage';
import productPersonalizerReducer from './productPersonalizer/slice';
import variantsReducer from './variants/slice';

export const i18n = createI18n(LOCAL_ENV.translationUrl);

export type ThunkArg = { i18n: typeof i18n };
const extraThunkArgument: ThunkArg = { i18n };

const rootReducer = combineReducers({
  app: appReducer,
  canvas: canvasReducer,
  design: designReducer,
  editor: editorReducer,
  hostSettings: hostSettingsReducer,
  gallery: galleryReducer,
  editorModules: editorModulesReducer,
  gridDesigns: gridDesignsReducer,
  collections: collectionsReducer,
  toastr: toastrReducer,
  plugins: pluginsReducer,
  rightbar: rightbarReducer,
  fonts: fontsReducer,
  variants: variantsReducer,
  mockup: mockupReducer,
  productPersonalizer: productPersonalizerReducer,
  drafts: draftsReducer,
  demoApp: demoAppReducer,
});

export function createReduxStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: { extraArgument: extraThunkArgument },
        serializableCheck: {
          ignoredActions: ['app/openPopupAction'],
          ignoredPaths: ['app.popup.data.onCancel', /^app\.popup\.data\.options\.\d+\.onConfirm$/],
          ignoredActionPaths: ['payload.i18n'],
        },
      }).prepend(variantStoragelistenerMiddleware.middleware),
    enhancers: (getDefaultEnhancers) => {
      const enhancers = getDefaultEnhancers();
      return new Tuple(enhancers[0], batchedSubscribeEnhancer());
    },
    devTools: LOCAL_ENV.env === EnvEnum.dev,
  });
}

const store = createReduxStore();

export type RootState = ReturnType<typeof rootReducer>;
export type PPStore = typeof store;

if (LOCAL_ENV.env === EnvEnum.dev) {
  (window as any).reduxStore = store;
}

export default store;
