import { createSlice } from '@reduxjs/toolkit';

import defaultFontStyles from 'editor/src/store/fonts/utils/defaultFontStyles';
import defaultImageStyles from 'editor/src/store/fonts/utils/defaultImageStyles';

import { DEFAULT_DASH_PATTERN } from 'editor/src/util/shapePresets';

import addUnlockSizeRatioMediaElementReducer from './reducer/addUnlockSizeRatioMediaElementReducer';
import disableSpreadReducer from './reducer/disableSpreadReducer';
import enableSpreadReducer from './reducer/enableSpreadReducer';
import removeUnlockSizeRatioMediaElementReducer from './reducer/removeUnlockSizeRatioMediaElementReducer';
import resetSettingsToDefaultReducer from './reducer/resetSettingsToDefaultReducer';
import setClipboardElementUuidReducer from './reducer/setClipboardElementUuidReducer';
import setCropModeElementUuidReducer from './reducer/setCropModeElementUuidReducer';
import setCurrentSpreadIndexReducer from './reducer/setCurrentSpreadIndexReducer';
import setCurrentStyleReducer from './reducer/setCurrentStyleReducer';
import setDefaultSettingsValueReducer from './reducer/setDefaultSettingsValueReducer';
import setDisabledSpreadsReducer from './reducer/setDisabledSpreadsReducer';
import setDragStateReducer from './reducer/setDragStateReducer';
import setElementManipulationModeReducer from './reducer/setElementManipulationModeReducer';
import setFocusedContentReducer from './reducer/setFocusedContentReducer';
import setFocusedMediaElementUuidReducer from './reducer/setFocusedMediaElementUuidReducer';
import setImagePatternScaleBasesReducer from './reducer/setImagePatternScaleBasesReducer';
import setIsLoadingReducer from './reducer/setIsLoadingReducer';
import setMobileMenuReducer from './reducer/setMobileMenuReducer';
import setPluginPreviewModeReducer from './reducer/setPluginPreviewModeReducer';
import setSelectedElementUuidsReducer from './reducer/setSelectedElementUuidsReducer';
import setSettingsValueReducer from './reducer/setSettingsValueReducer';
import setTextEditModeReducer from './reducer/setTextEditModeReducer';
import setTrackDesignChangesReducer from './reducer/setTrackDesignChangesReducer';
import setUploaderConfigReducer from './reducer/setUploaderConfigReducer';
import setZoomModeReducer from './reducer/setZoomModeReducer';
import { EditorState, Settings, SettingsProperty } from './types';

const defaultSettings: Settings = {
  [SettingsProperty.showGrid]: false,
  [SettingsProperty.showContentAreas]: false,
  [SettingsProperty.showDescriptions]: false,
  [SettingsProperty.showGuides]: true,
  [SettingsProperty.showBleeds]: true,
  [SettingsProperty.showWrapsAndJoints]: false,
  [SettingsProperty.units]: 'cm',
  [SettingsProperty.showRulers]: true,
  [SettingsProperty.showRollerStripes]: true,
  [SettingsProperty.showPerspectiveLines]: false,
};

export const editorInitialState: EditorState = {
  settings: defaultSettings,
  defaultSettings,
  currentSpreadIndex: 0,
  selectedElementUuids: [],
  cropModeElementUuid: undefined,
  clipboardElementUuid: undefined,
  elementManipulationMode: undefined,
  focusedMediaElementUuid: undefined,
  pluginPreviewMode: undefined,
  unlockSizeRatioElementUuids: [],
  disabledSpreads: [],
  imagePatternScaleBases: [],
  trackDesignChanges: false,
  textEditMode: false,
  zoomMode: false,
  focusedContentAddress: undefined,
  currentStyles: {
    line: { dashPattern: DEFAULT_DASH_PATTERN },
    rectangle: { dashPattern: DEFAULT_DASH_PATTERN },
    text: {
      stroke: defaultFontStyles.stroke,
      shadow: defaultFontStyles.shadow,
      fontFamily: defaultFontStyles.fontFamily,
      curve: defaultFontStyles.curve,
    },
    image: { shadow: defaultImageStyles.shadow },
  },
  uploaderConfig: undefined,
  dragState: undefined,
  isLoading: false,
  mobileMenu: {
    isOpen: false,
  },
};

const slice = createSlice({
  name: 'editor',
  initialState: editorInitialState,
  reducers: {
    setCurrentSpreadIndexAction: setCurrentSpreadIndexReducer,
    setSelectedElementUuidsAction: setSelectedElementUuidsReducer,
    setCropModeElementUuidAction: setCropModeElementUuidReducer,
    enableSpreadAction: enableSpreadReducer,
    disableSpreadAction: disableSpreadReducer,
    setDisabledSpreadsAction: setDisabledSpreadsReducer,
    setImagePatternScaleBasesAction: setImagePatternScaleBasesReducer,
    setTrackDesignChangesAction: setTrackDesignChangesReducer,
    setSettingsValueAction: setSettingsValueReducer,
    setDefaultSettingsValueAction: setDefaultSettingsValueReducer,
    resetSettingsToDefaultAction: resetSettingsToDefaultReducer,
    setFocusedMediaElementUuidAction: setFocusedMediaElementUuidReducer,
    setPluginPreviewModeAction: setPluginPreviewModeReducer,
    addUnlockSizeRatioMediaElementAction: addUnlockSizeRatioMediaElementReducer,
    removeUnlockSizeRatioMediaElementAction: removeUnlockSizeRatioMediaElementReducer,
    setTextEditModeAction: setTextEditModeReducer,
    setZoomModeAction: setZoomModeReducer,
    setFocusedContentAction: setFocusedContentReducer,
    setCurrentStyleAction: setCurrentStyleReducer,
    setUploaderConfigAction: setUploaderConfigReducer,
    setDragStateAction: setDragStateReducer,
    setElementManipulationModeAction: setElementManipulationModeReducer,
    setClipboardElementUuidAction: setClipboardElementUuidReducer,
    setIsLoadingAction: setIsLoadingReducer,
    setMobileMenuAction: setMobileMenuReducer,
  },
});

const { actions, reducer } = slice;

export const {
  setCurrentSpreadIndexAction,
  setSelectedElementUuidsAction,
  setCropModeElementUuidAction,
  enableSpreadAction,
  disableSpreadAction,
  setDisabledSpreadsAction,
  setImagePatternScaleBasesAction,
  setTrackDesignChangesAction,
  setSettingsValueAction,
  setDefaultSettingsValueAction,
  resetSettingsToDefaultAction,
  setFocusedMediaElementUuidAction,
  addUnlockSizeRatioMediaElementAction,
  removeUnlockSizeRatioMediaElementAction,
  setTextEditModeAction,
  setZoomModeAction,
  setFocusedContentAction,
  setCurrentStyleAction,
  setUploaderConfigAction,
  setDragStateAction,
  setPluginPreviewModeAction,
  setElementManipulationModeAction,
  setClipboardElementUuidAction,
  setIsLoadingAction,
  setMobileMenuAction,
} = actions;

export default reducer;
