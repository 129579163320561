import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import setImageIfCurrentImageIsEmptyOperation from 'editor/src/store/editor/operation/setImageIfCurrentImageIsEmptyOperation';
import isUseNotUploadedImagesAllowed from 'editor/src/store/editor/selector/isUseNotUploadedImagesAllowed';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { addImageAction, addImageToSessionAction, updateImageAction } from 'editor/src/store/gallery/slice';
import { GalleryImage, ImageState, RemotePreviewImageData } from 'editor/src/store/gallery/types';
import getMimeTypeEnum from 'editor/src/store/gallery/utils/getMimeTypeEnum';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import loadImageDimensions from 'editor/src/util/loadImageDimensions';

const addImageRemotePreviewFromUploader =
  (imagePreview: RemotePreviewImageData) => async (dispatch: ThunkDispatch, getState: () => RootState) => {
    const hasAssetDimensions = !!imagePreview.width && !!imagePreview.height;
    const mimeType = getMimeTypeEnum(imagePreview.type);

    const image: Omit<GalleryImage, 'uuid'> = {
      state: ImageState.REMOTE_PREVIEW,
      id: imagePreview.id,
      uploaderId: imagePreview.id,
      name: imagePreview.name,
      type: mimeType,
      width: imagePreview.width ?? 0,
      height: imagePreview.height ?? 0,
      url: imagePreview.preview,
      label: imagePreview.label,
      source: imagePreview.source,
      hasAssetDimensions,
    };

    const canUseImage = isUseNotUploadedImagesAllowed(getState());

    batch(() => {
      dispatch(addImageAction(image));
      dispatch(addImageToSessionAction(imagePreview.id));

      if (hasAssetDimensions && canUseImage) {
        dispatch(setImageIfCurrentImageIsEmptyOperation(imagePreview.id));
      }
    });

    if (!hasAssetDimensions) {
      try {
        const { width, height } = await loadImageDimensions(imagePreview.preview, undefined, {
          executor: 'addImageRemotePreviewFromUploader',
        });
        const state = getState();
        const image = getImageById(state, imagePreview.id);
        if (image && image.state === ImageState.REMOTE_PREVIEW) {
          batch(() => {
            dispatch(updateImageAction({ id: imagePreview.id, width, height }));
            if (canUseImage) {
              dispatch(setImageIfCurrentImageIsEmptyOperation(imagePreview.id));
            }
          });
        }
      } catch {
        /**/
      }
    }
  };

export default addImageRemotePreviewFromUploader;
