import { FontDefinition } from 'editor/src/store/fonts/types';

function findMatchingFont(
  fonts: FontDefinition[],
  family: string,
  weight: string | undefined,
  style: string | undefined,
) {
  return fonts.find((font) => {
    const familyMatch = font.metadata.fontFile === family || font.metadata.family === family;
    const styleMatch =
      ((!weight && !font.metadata.weight) || font.metadata.style?.toLowerCase() === weight) &&
      ((!style && !font.metadata.style) || font.metadata.style?.toLowerCase() === style);
    return familyMatch && styleMatch;
  });
}

export default findMatchingFont;
