import { DropShadow, SpreadGroup } from 'editor/src/store/design/types';
import { FontStyles } from 'editor/src/store/fonts/types';

export interface LayoutsState {
  schemas: LayoutSchema[];
}

interface ILayoutFrameArea {
  width?: number;
  height?: number;
  shiftX?: number;
  shiftY?: number;
  shiftWidth?: number;
  shiftHeight?: number;
  padding?: LayoutPadding;
  static?: boolean;
  linkId?: string;
  id?: string;
  role?: string;
  freePlacement?: 'center';
}

export interface ImageLayoutFrameArea extends ILayoutFrameArea {
  type?: 'image';
  saveSourceFrame?: true;
  shadow?: DropShadow;
}

export interface GridLayoutFrameArea extends ILayoutFrameArea {
  type: 'grid';
  designName: string;
}

export enum LayoutGeneratedTextType {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export interface GeneratedTextLayoutFrameArea extends ILayoutFrameArea {
  type: 'generated-text';
  textType: LayoutGeneratedTextType;
  vAlignment?: 'top' | 'bottom' | 'middle';
}

export interface TextLayoutFrameArea extends ILayoutFrameArea {
  type: 'text';
  textI18nKey?: string;
  fontStyles?: Partial<FontStyles>;
}

export type LayoutFrameArea =
  | ImageLayoutFrameArea
  | TextLayoutFrameArea
  | GridLayoutFrameArea
  | GeneratedTextLayoutFrameArea;

export interface LayoutFrame<T extends LayoutFrameArea = LayoutFrameArea> {
  x: number;
  y: number;
  width: number;
  height: number;
  frameArea: T;
  id: string;
}

export interface LayoutOffset {
  horizontal: number;
  vertical: number;
}

export interface LayoutPadding {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export interface LayoutSchema {
  name: string;
  tags?: string[];
  params: {
    cols: number;
    rows: number;
    shiftSteps: number;
    spacing: LayoutOffset;
    padding: LayoutPadding;
    offset: LayoutPadding;
    useBleed: boolean;
    useContentAreasOnly?: boolean;
  };
  frameRows: LayoutFrameArea[][];
}

export interface LayoutGroup {
  layouts: string[]; // matching of layout name per group spread or page
  tag: string;
  name: string;
}

export interface LayoutGroupInfo {
  spreadGroup: SpreadGroup;
  layoutGroup: LayoutGroup;
}
