import cn from 'classnames';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { Content } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import requestSinglePreviewOperation from 'editor/src/store/editorModules/preview/operation/requestSinglePreviewOperation';
import resetSpreadPreviewOperation from 'editor/src/store/editorModules/preview/operation/resetSpreadPreviewOperation';
import getFlatPreviews from 'editor/src/store/editorModules/preview/selector/getFlatPreviews';
import getPreviewStatus from 'editor/src/store/editorModules/preview/selector/getPreviewStatus';
import getSpreadPreview from 'editor/src/store/editorModules/preview/selector/getSpreadPreview';
import { PreviewStatus } from 'editor/src/store/editorModules/preview/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import useSpreadTitle from 'editor/src/util/design/useSpreadTitle';
import notNull from 'editor/src/util/notNull';

import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import Carousel from './Carousel';
import FlatPreviewList from './FlatPreviewList';
import { PreviewItem } from './FlatPreviewList/ListItem';
import SpreadPreviewNavigation from './SpreadPreviewNavigation';
import useFlatPreviews from './useFlatPreviews';

import styles from './index.module.scss';

export const BACK_PREVIEW_KEY = 'back';

function FlatPreview() {
  const isMobile = useIsMobile();
  const spreadPreviews = useFlatPreviews();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const { storeItems, perSpreadPreviewAvailable, spreadPreview, status, designStructure, currentSpreadIndex } =
    useSelector(
      (state) => ({
        storeItems: getFlatPreviews(state),
        perSpreadPreviewAvailable: getHostSetting(state, 'perSpreadPreviewAvailable'),
        spreadPreview: getSpreadPreview(state),
        status: getPreviewStatus(state),
        designStructure: state.design.designData,
        currentSpreadIndex: getCurrentSpreadIndex(state),
      }),
      shallowEqual,
    );
  const [singlePreviewIndex, setSinglePreviewIndex] = useState(currentSpreadIndex ?? 0);

  const contentBackOnFirstSpread = useMemo(() => {
    return designStructure?.spreads[0].pages[0].groups.content?.some((area: Content) => area.name === 'Content Back');
  }, [designStructure?.spreads[0]]);

  const isContentBackOnFirstSpread = !!(
    contentBackOnFirstSpread &&
    perSpreadPreviewAvailable &&
    singlePreviewIndex === designStructure?.spreads.length
  );

  const debouncedRequestSinglePreviewOperation = useCallback(
    debounce((previewIndex) => {
      let previewExists = spreadPreview[previewIndex];
      let spreadIndex = previewIndex;
      if (isContentBackOnFirstSpread) {
        spreadIndex = 0;
        previewExists = spreadPreview[BACK_PREVIEW_KEY];
      }

      if (!previewExists) {
        dispatch(requestSinglePreviewOperation(spreadIndex, isContentBackOnFirstSpread));
      }
    }, 300),
    [isContentBackOnFirstSpread, spreadPreview],
  );

  useEffect(() => {
    return () => {
      dispatch(resetSpreadPreviewOperation());
    };
  }, []);

  const isNextAvailable = useCallback(
    (currentIndex: number) => {
      if (!designStructure) {
        return false;
      }

      return (
        currentIndex < designStructure.spreads.length - 1 ||
        !!(currentIndex < designStructure.spreads.length && contentBackOnFirstSpread)
      );
    },
    [designStructure?.spreads.length, contentBackOnFirstSpread],
  );

  useEffect(() => {
    if (perSpreadPreviewAvailable && designStructure) {
      debouncedRequestSinglePreviewOperation(singlePreviewIndex);
    }
  }, [perSpreadPreviewAvailable, singlePreviewIndex, designStructure]);

  const spreadTitle = useSpreadTitle(
    isContentBackOnFirstSpread ? t('Back') : designStructure?.spreads[singlePreviewIndex]?.name ?? '',
    isContentBackOnFirstSpread ? 0 : singlePreviewIndex,
    undefined,
  );

  const items = useMemo(() => {
    if (perSpreadPreviewAvailable) {
      if (!designStructure) {
        return [];
      }

      let previewIndex = `${singlePreviewIndex}`;
      if (isContentBackOnFirstSpread) {
        previewIndex = BACK_PREVIEW_KEY;
      }

      return spreadPreview[previewIndex] ? ([spreadPreview[previewIndex]] as PreviewItem[]) : [];
    }
    const noPreviewsAvailable = !spreadPreviews.length && !storeItems.length;
    if (noPreviewsAvailable) {
      return [];
    }
    const flatPreviews: PreviewItem[] = spreadPreviews.filter(notNull).map(({ blob }) => ({ name: '', blob }));
    const additionalItems = status === PreviewStatus.LOADED ? storeItems : [{ url: '', name: '' }];

    return flatPreviews.concat(additionalItems);
  }, [storeItems, status, perSpreadPreviewAvailable, spreadPreview, spreadPreviews, isContentBackOnFirstSpread]);
  const currentActiveIndex = activeIndex < items.length ? activeIndex : 0;

  const onNextSinglePreviewClick = useCallback(() => {
    setSinglePreviewIndex((prev) => prev + 1);
  }, []);

  const onPrevSinglePreviewClick = useCallback(() => {
    setSinglePreviewIndex((prev) => prev - 1);
  }, []);

  const onSinglePreviewSwipeRight = useCallback(() => {
    setSinglePreviewIndex((prevIndex) => {
      if (perSpreadPreviewAvailable && prevIndex > 0) {
        return prevIndex - 1;
      }
      return prevIndex;
    });
  }, [perSpreadPreviewAvailable]);

  const onSinglePreviewSwipeLeft = useCallback(() => {
    setSinglePreviewIndex((prevIndex) => {
      if (!designStructure) {
        return prevIndex;
      }
      if (perSpreadPreviewAvailable && isNextAvailable(prevIndex)) {
        return prevIndex + 1;
      }
      return prevIndex;
    });
  }, [perSpreadPreviewAvailable, designStructure?.spreads.length, isNextAvailable]);

  return (
    <div
      className={cn(styles.FlatPreview, 'cy-flat-preview', {
        [styles.mobile]: isMobile,
      })}
    >
      <Carousel
        items={items}
        isMobile={isMobile}
        activeIndex={currentActiveIndex}
        setActiveIndex={setActiveIndex}
        onSwipeLeft={onSinglePreviewSwipeLeft}
        onSwipeRight={onSinglePreviewSwipeRight}
      />
      {!isMobile && items.length > 1 && (
        <FlatPreviewList items={items} activeIndex={currentActiveIndex} setActiveIndex={setActiveIndex} />
      )}
      {perSpreadPreviewAvailable && (
        <SpreadPreviewNavigation
          isPrevAvailable={singlePreviewIndex > 0}
          isNextAvailable={isNextAvailable(singlePreviewIndex)}
          name={spreadTitle}
          onPrevClick={onPrevSinglePreviewClick}
          onNextClick={onNextSinglePreviewClick}
        />
      )}
    </div>
  );
}

export default React.memo(FlatPreview);
