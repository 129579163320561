import getCurrentGridDesign from 'editor/src/store/design/selector/getCurrentGridDesign';
import { getSpreadHeightFromSpread } from 'editor/src/store/design/selector/getSpreadHeight';
import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import { MediaElement, Spread } from 'editor/src/store/design/types';
import isMediaGrid from 'editor/src/store/design/util/isMediaGrid';
import { LayoutFrame } from 'editor/src/store/editorModules/layouts/types';
import { MockupRole } from 'editor/src/store/mockup/types';

import { RootState } from 'editor/src/store';
import notUndefined from 'editor/src/util/notUndefined';

import createGeneratedTextMediaElement from './createGeneratedTextMediaElement';
import createGridMediaElement from './createGridMediaElement';
import createImageMediaElement from './createImageMediaElement';
import createMockupPlaceholderElement from './createMockupPlaceholderElement';
import createTextMediaElement from './createTextMediaElement';
import getGridDefaults from './getGridDefault';
import { getImageElementName, getTextElementName } from './layoutElementNameGenerator';

import type { i18n } from 'i18next';

function getGridElementsDefauts(state: RootState, spread: Spread, i18n: i18n) {
  const gridDesign = getCurrentGridDesign(state.gridDesigns.grids, spread);
  const gridInfo = spread.pages[0].groups.media?.find(isMediaGrid)?.grid ?? getGridDefaults(i18n, spread.name);
  return { gridDesign, gridInfo };
}

const generateLayoutMediaElements = (
  state: RootState,
  spreadIndex: number,
  spread: Spread,
  layoutFrames: LayoutFrame[],
  i18n: i18n,
): MediaElement[] => {
  const { defaultPersonalizationLock, flagNewTextElementsAsSample } = state.hostSettings;
  const { defaultFontStyles } = state.fonts;
  const defaultText = i18n.t('editor-new-text-sample');
  const spreadWidth = getSpreadWidthFromSpread(spread);
  const spreadHeight = getSpreadHeightFromSpread(spread);

  let pageElementIndex = 0;
  return layoutFrames
    .map((frame) => {
      pageElementIndex += 1;
      switch (frame.frameArea.type) {
        case 'text':
          return createTextMediaElement(
            frame,
            defaultText,
            defaultFontStyles,
            defaultPersonalizationLock,
            flagNewTextElementsAsSample,
            spreadIndex,
            pageElementIndex,
            i18n,
          );
        case 'generated-text': {
          const { gridInfo, gridDesign } = getGridElementsDefauts(state, spread, i18n);
          return createGeneratedTextMediaElement(
            frame,
            frame.frameArea,
            gridInfo,
            gridDesign,
            spread.name,
            spreadWidth,
            state.fonts.availableFonts,
            defaultFontStyles,
            getTextElementName(spreadIndex, pageElementIndex),
          );
        }
        case 'grid': {
          const { gridInfo, gridDesign } = getGridElementsDefauts(state, spread, i18n);
          return createGridMediaElement(frame, gridInfo, gridDesign, spreadWidth);
        }
        case 'image':
        default:
          if (frame.frameArea.role === MockupRole.Placeholder) {
            return createMockupPlaceholderElement(
              frame,
              frame.frameArea,
              defaultPersonalizationLock,
              getImageElementName(spreadIndex, pageElementIndex),
              state.mockup,
              spreadWidth,
              spreadHeight,
            );
          }

          return createImageMediaElement(
            frame,
            frame.frameArea,
            defaultPersonalizationLock,
            getImageElementName(spreadIndex, pageElementIndex),
          );
      }
    })
    .filter(notUndefined);
};

export default generateLayoutMediaElements;
