import { GridDesign } from '@gelatoas/design-editor-calendar';
import { PayloadAction } from '@reduxjs/toolkit';

import { GridDesignsState } from 'editor/src/store/gridDesigns/types';

const setGridDesignsReducer = (state: GridDesignsState, action: PayloadAction<GridDesign[]>) => {
  state.grids = action.payload;
};

export default setGridDesignsReducer;
