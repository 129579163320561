import { captureException } from '@sentry/react';
import React, { ReactPortal, RefObject, createContext, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import addImageLocalPreviewFromUploader from 'editor/src/store/gallery/operation/addImageLocalPreviewFromUploader';
import addImageRemotePreviewFromUploader from 'editor/src/store/gallery/operation/addImageRemotePreviewFromUploader';
import updateImageOnFailedUpload from 'editor/src/store/gallery/operation/updateImageOnFailedUpload';
import updateImageOnProgress from 'editor/src/store/gallery/operation/updateImageOnProgress';
import updateImageOnStartOperation from 'editor/src/store/gallery/operation/updateImageOnStartOperation';
import { logUpload, PayloadFile, registerUpload } from 'editor/src/store/gallery/utils/uploadLogging';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { fileToPayloadFile } from 'editor/src/util/localUploader/fileMappers';
import { isLocalFileSizeValid, MAX_LOCAL_FILE_SIZE_MB } from 'editor/src/util/localUploader/locaFileValidators';
import notUndefined from 'editor/src/util/notUndefined';
import toastController from 'editor/src/util/toastController';

import UploaderMobileMenu, {
  INLINE_UPLOADER_TARGET_CLASS_NAME,
} from '../BottomBarMobile/ActionBarSection/UploaderMobileMenu';
import useMobileMenu from '../useMobileMenu';

import type { FileUploaderConfig, FileUploader } from '@gelatoas/file-uploader';

import '@gelatoas/file-uploader/lib/index.css';

export type Uploader = {
  open: (onClose?: () => void) => void;
  close: () => void;
  retryUpload: (imageId: string) => void;
  retryAll: () => void;
  upload: (files: File[]) => Array<{ file: File; id: string }>;
  isUploading: () => boolean;
  isOpen: () => boolean;
  renderMenu?: () => ReactPortal | null;
};

export interface SuccessPayload {
  file: PayloadFile;
  url: string;
}

export const UploaderContext = createContext<Uploader>(null as any);
const UPLOADER_OPEN_STATE = { uploaderOpen: true };
function isUploaderState(state = window.history.state): boolean {
  return state?.uploaderOpen === true;
}

function useUploader(
  isMobile: boolean,
  onUploadSuccess: (payload: SuccessPayload) => void,
  useLocalUploader?: boolean,
  localUploaderInputRef?: RefObject<HTMLInputElement>,
): Uploader {
  const dispatch = useDispatch();
  const uploaderMenu = useMobileMenu(false);
  const fileUploaderRef = useRef<FileUploader>();
  const onCloseCbRef = useRef<Array<() => void>>([]);
  const onUploadSuccessRef = useRef<(payload: SuccessPayload) => void>(onUploadSuccess);
  onUploadSuccessRef.current = onUploadSuccess;
  const config = useSelector((state) => state.editor.uploaderConfig);
  const configRef = useRef(config);
  configRef.current = config;
  const modalOpened = useRef(false);
  const isMobileRef = useRef(false);
  isMobileRef.current = isMobile;
  const uploaderMenuRef = useRef(uploaderMenu);
  uploaderMenuRef.current = uploaderMenu;
  const { t } = useTranslation();

  useEffect(() => {
    if (!configRef.current || useLocalUploader) {
      return undefined;
    }

    if (!isMobileRef.current) {
      initializeUploader({
        ...configRef.current,
        dashboard: {
          ...configRef.current?.dashboard,
          closeModalOnClickOutside: true,
        },
        thumbnailGenerator: {
          clearMemoryAfterUpload: false,
        },
      });
    }

    function onPopState(e: PopStateEvent) {
      if (isUploaderState(e.state)) {
        openMenu();
      } else if (modalOpened.current) {
        closeMenu();
      }
    }

    if (isMobileRef.current) {
      window.addEventListener('popstate', onPopState);
    }
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, [configRef.current, isMobileRef.current]);

  const initializeUploader = useCallback((config: FileUploaderConfig, onUploadSuccess?: () => void) => {
    // lazily importing the library to split the JS bundle
    void import('@gelatoas/file-uploader').then(({ FileUploader, FileUploaderMessage }) => {
      fileUploaderRef.current = new FileUploader(config, (message, payload: any) => {
        switch (message) {
          case FileUploaderMessage.uploadStart: {
            if (payload.fileIDs?.length) {
              dispatch(updateImageOnStartOperation(payload.fileIDs));
            }

            if (isMobileRef.current) {
              closeMenu();
            }
            break;
          }
          case FileUploaderMessage.opened:
            modalOpened.current = true;
            break;
          case FileUploaderMessage.closeModal:
          case FileUploaderMessage.closed:
            modalOpened.current = false;
            onCloseCbRef.current.forEach((cb) => cb());
            onCloseCbRef.current = [];
            if (isMobileRef.current && isUploaderState()) {
              window.history.back();
            }
            break;
          case FileUploaderMessage.previewGeneratedSuccess:
            void dispatch(addImageLocalPreviewFromUploader(payload));
            break;
          case FileUploaderMessage.previewGeneratedEmptySuccess:
            void dispatch(
              addImageLocalPreviewFromUploader({
                ...payload,
                placeholder: true,
                preview: null,
              }),
            );
            break;
          case FileUploaderMessage.previewGeneratedRemotelySuccess:
            void dispatch(addImageRemotePreviewFromUploader(payload));
            break;
          case FileUploaderMessage.uploadSuccess: {
            onUploadSuccess?.();
            registerUpload(payload.file);
            onUploadSuccessRef.current?.(payload);
            try {
              fileUploaderRef.current?.uppy.removeFile(payload.file.id);
            } catch (e) {
              captureException(e);
            }
            break;
          }
          case FileUploaderMessage.uploadError: {
            const file = payload.file as PayloadFile;
            registerUpload(file);
            logUpload(file.id, Date.now(), 'upload-failed');
            dispatch(updateImageOnFailedUpload(file.id));
            toastController.warningPersist(t('editor-image-issue'), t('editor-image-upload-failed'));
            fileUploaderRef.current?.uppy.setFileState(file.id, { s3Multipart: undefined }); // reset the state to allow retry
            break;
          }
          case FileUploaderMessage.uploadProgress:
            dispatch(
              updateImageOnProgress({
                file: payload.file,
                bytesUploaded: payload.progress.bytesUploaded,
                bytesTotal: payload.progress.bytesTotal,
              }),
            );
            break;
          case FileUploaderMessage.openShutterstock:
            dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.SHUTTERSTOCK, true));
            break;
          default:
            break;
        }
      });
    });
  }, []);

  const closeMenu = () => {
    uploaderMenuRef.current.close();
    modalOpened.current = false;
  };

  const openMenu = () => {
    if (configRef.current) {
      uploaderMenuRef.current.open();
      initializeUploader({
        ...configRef.current,
        dashboard: {
          ...configRef.current.dashboard,
          inline: true,
          target: `.${INLINE_UPLOADER_TARGET_CLASS_NAME}`,
          height: '100%',
        },
        thumbnailGenerator: {
          clearMemoryAfterUpload: false,
        },
      });
      modalOpened.current = true;
    }
  };

  const uploader = useMemo(() => {
    if (useLocalUploader) {
      return {
        open: () => {
          if (!localUploaderInputRef?.current) {
            return;
          }

          localUploaderInputRef.current.click();
        },
        retryUpload: async () => {},
        retryAll: async () => {},
        upload: (files: File[]) => {
          let allFilesValid = true;
          const validFiles = files.filter((file) => {
            const isValid = isLocalFileSizeValid(file);
            allFilesValid = allFilesValid && isValid;
            return isValid;
          });

          if (!allFilesValid) {
            toastController.warningPersist(
              t('demo-editor-file-is-too-big-title'),
              t('demo-editor-file-is-too-big-description', { maxFileSizeMb: MAX_LOCAL_FILE_SIZE_MB }),
            );
          }

          return validFiles.map((file) => {
            const uuid = `${file.name}-${file.size}-${file.lastModified}`;
            fileToPayloadFile(file)
              .then((successPayload) => onUploadSuccessRef.current?.(successPayload))
              .catch((error) => {
                throw new Error(error);
              });
            return { file, id: uuid };
          });
        },
        isUploading: () => false,
        isOpen: () => false,
        close: () => {},
      };
    }

    return {
      renderMenu: () => {
        return uploaderMenuRef.current.render(<UploaderMobileMenu closeMenuCb={closeMenu} />);
      },
      open: (onClose?: () => void) => {
        if (isMobileRef.current && configRef.current) {
          openMenu();
        } else {
          fileUploaderRef.current?.show();
        }
        if (isMobileRef.current && !isUploaderState()) {
          window.history.pushState(UPLOADER_OPEN_STATE, '');
        }
        if (onClose) {
          onCloseCbRef.current.push(onClose);
        }
      },
      retryUpload: async (imageId: string) => {
        try {
          await fileUploaderRef.current?.uppy.retryUpload(imageId);
        } catch (e) {
          captureException(e);
        }
      },
      retryAll: async () => {
        try {
          await fileUploaderRef.current?.uppy.retryAll();
        } catch (e) {
          captureException(e);
        }
      },
      upload: (files: File[]) => {
        const uploads = files
          .map((file) => {
            try {
              const id = fileUploaderRef.current?.uppy.addFile({
                data: file,
                name: file.name,
                size: file.size,
                type: file.type,
              });
              return id ? { file, id } : undefined;
            } catch {
              return undefined;
            }
          })
          .filter(notUndefined);

        if (uploads.length) {
          fileUploaderRef.current?.uppy.upload().catch((e) => captureException(e));
        }

        return uploads;
      },
      isUploading: () => !!fileUploaderRef.current?.uppy.getFiles().length,
      isOpen: () => modalOpened.current,
      close: () => {
        if (isMobileRef.current && isUploaderState()) {
          window.history.back();
        } else {
          fileUploaderRef.current?.close();
        }
      },
    };
  }, [useLocalUploader]);

  return uploader;
}

export default useUploader;
