import { GridDesign, toSize, generatorUtils } from '@gelatoas/design-editor-calendar';

import { MediaGridInfo, MediaText } from 'editor/src/store/design/types';
import {
  GeneratedTextLayoutFrameArea,
  LayoutFrame,
  LayoutGeneratedTextType,
} from 'editor/src/store/editorModules/layouts/types';
import { FontDefinition, FontStyles, TextAlign } from 'editor/src/store/fonts/types';
import findMatchingFont from 'editor/src/store/fonts/utils/findMatchingFont';

import fontStylesToTextElement from 'editor/src/util/fontStylesToTextElement';
import mm2pt from 'editor/src/util/mm2pt';
import { elementUuids } from 'editor/src/util/uuids';

import updateTextElementWithoutRender from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Text/updateTextElementWithoutRender';

import { alignTextVertically } from './layoutTextUtils';

export function getGeneratedMonthText(gridInfo: MediaGridInfo, type: 'number' | 'name' | undefined) {
  const selectedDate = new Date(gridInfo.gridDate.year, gridInfo.gridDate.month, 1);
  return type === 'number'
    ? `${selectedDate.getMonth() + 1}`
    : selectedDate.toLocaleString(gridInfo.locale, {
        month: 'long',
        caseFirst: 'upper',
      } as any);
}

export function getGeneratedYearText(gridInfo: MediaGridInfo) {
  return `${gridInfo.gridDate.year}`;
}

export function getGeneratedTextInfo(
  textType: LayoutGeneratedTextType,
  gridInfo: MediaGridInfo,
  gridDesign: GridDesign,
  spreadName: string,
  spreadWidth: number,
) {
  let textValue = '';
  let textDesign: GridDesign['month'] | undefined;
  let d2f: toSize | undefined;

  switch (textType) {
    case LayoutGeneratedTextType.MONTH: {
      d2f = generatorUtils.getToSizeFn(gridDesign, spreadWidth);
      textDesign = gridDesign.month;
      textValue = getGeneratedMonthText(gridInfo, gridDesign.month.type);
      break;
    }
    case LayoutGeneratedTextType.YEAR: {
      d2f = generatorUtils.getToSizeFn(gridDesign, spreadWidth);
      textDesign = gridDesign.year;
      textValue = getGeneratedYearText(gridInfo);
      break;
    }
    default:
      break;
  }

  return { textValue, textDesign, d2f };
}

function hAlignmentToTextAlign(align: GridDesign['month']['align']) {
  return align as TextAlign;
}

export function applyTextPropertiesToTextElement(
  element: MediaText,
  properties: GridDesign['month'],
  fonts: FontDefinition[],
  d2f: toSize,
) {
  const matchingFont = findMatchingFont(fonts, properties.fontFamily, properties.fontWeight, properties.fontStyle);
  if (!matchingFont) {
    return;
  }
  element.fill = properties.color;
  element.extra.fontFamily = matchingFont.metadata.fontFile;
  element.extra.textAlign = hAlignmentToTextAlign(properties.align) ?? TextAlign.left;
  element.extra.fontSize = mm2pt(d2f(properties.fontSize));
  element.extra.lineHeight = 1;
}

const createGeneratedTextMediaElement = (
  frame: LayoutFrame,
  frameArea: GeneratedTextLayoutFrameArea,
  gridInfo: MediaGridInfo,
  gridDesign: GridDesign,
  spreadName: string,
  spreadWidth: number,
  availableFonts: FontDefinition[],
  defaultFontStyles: FontStyles,
  elementName: string,
): MediaText | undefined => {
  const { textValue, textDesign, d2f } = getGeneratedTextInfo(
    frameArea.textType,
    gridInfo,
    gridDesign,
    spreadName,
    spreadWidth,
  );
  if (!textValue || !textDesign || !d2f) {
    return undefined;
  }

  const textElement: MediaText = {
    type: 'text',
    group: '',
    generatedType: frameArea.textType,
    name: elementName,
    x: frame.x,
    y: frame.y,
    width: frame.width,
    height: frame.height,
    sx: 1,
    sy: 1,
    rendered_with: 'opentype.js',
    personalizationLocked: true,
    uuid: elementUuids.generate(),
    uneditable: true,
    createdByLayout: true,
    layoutFrameId: frame.id,
    ...fontStylesToTextElement(defaultFontStyles, textValue),
    sourceFrame: { width: frame.width, height: frame.height },
  };

  applyTextPropertiesToTextElement(textElement, textDesign, availableFonts, d2f);
  updateTextElementWithoutRender(textElement, frame);
  alignTextVertically(textElement, frame.height, frameArea.vAlignment);

  return textElement;
};

export default createGeneratedTextMediaElement;
