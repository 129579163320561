import cn from 'classnames';
import React, { ReactNode } from 'react';

import Button from 'editor/src/component/Button';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from './index.module.scss';

interface Props {
  onSave: () => void;
  onReset: () => void;
  onClose: () => void;
  children: ReactNode;
}

function Modal({ onSave, onReset, onClose, children }: Props) {
  const isMobile = useIsMobile();
  const { clientWidth, clientHeight } = document.documentElement;
  const width = Math.min(1400, Math.max(400, Math.round(clientWidth * 0.8)));
  const height = Math.min(1000, Math.max(400, Math.round(clientHeight * 0.8)));
  const left = (clientWidth - width) / 2;
  const top = (clientHeight - height) / 2;

  return (
    <div className={cn(styles.modal, { [styles.mobile]: isMobile })}>
      <div className={styles.backdrop} onClick={onClose} />
      <div
        className={styles.modalContent}
        style={
          isMobile
            ? undefined
            : {
                width,
                height,
                left,
                top,
              }
        }
      >
        <div className={cn(styles.header, 'p-2')}>Adjust your image</div>
        <div className={styles.content}>{children}</div>
        <div className={cn(styles.buttons, 'p-2')}>
          <Button onClick={onReset}>Reset</Button>
          <div className={styles.buttonGroup}>
            <Button onClick={onClose}>Cancel</Button>
            <Button primary className="ml-2" onClick={onSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
