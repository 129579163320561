import { createSlice } from '@reduxjs/toolkit';

import addConditionGroupReducer from './reducer/addConditionGroupReducer';
import addConditionOptionReducer from './reducer/addConditionOptionReducer';
import addConditionToConditionReducer from './reducer/addConditionToConditionReducer';
import addDigitizedAssetReducer from './reducer/addDigitizedAssetReducer';
import addElementToConditionGroupReducer from './reducer/addElementToConditionGroupReducer';
import addElementToConditionReducer from './reducer/addElementToConditionReducer';
import addMediaElementReducer from './reducer/addMediaElementReducer';
import clearImageElementsByImageIdReducer from './reducer/clearImageElementsByImageIdReducer';
import createEmptyConditionReducer from './reducer/createEmptyConditionReducer';
import deleteConditionReducer from './reducer/deleteConditionReducer';
import markDesignDataAsTouchedReducer from './reducer/markDesignDataAsTouchedReducer';
import moveMediaElementZindexBottomReducer from './reducer/moveMediaElementZindexBottomReducer';
import moveMediaElementZindexDownReducer from './reducer/moveMediaElementZindexDownReducer';
import moveMediaElementZindexReducer from './reducer/moveMediaElementZindexReducer';
import moveMediaElementZindexTopReducer from './reducer/moveMediaElementZindexTopReducer';
import moveMediaElementZindexUpReducer from './reducer/moveMediaElementZindexUpReducer';
import removeAllMediaElementsFromPageReducer from './reducer/removeAllMediaElementsFromPageReducer';
import removeConditionFromConditionReducer from './reducer/removeConditionFromConditionReducer';
import removeConditionOptionReducer from './reducer/removeConditionOptionReducer';
import removeElementFromConditionReducer from './reducer/removeElementFromConditionReducer';
import removeElementFromGroupConditionReducer from './reducer/removeElementFromGroupConditionReducer';
import removeMediaElementAdjustmentsReducer from './reducer/removeMediaElementAdjustmentsReducer';
import removeMediaElementReducer from './reducer/removeMediaElementReducer';
import reOrderSpreadsReducer from './reducer/reOrderSpreadsReducer';
import resetDigitizedAssetReducer from './reducer/resetDigitizedAssetReducer';
import setAssetProviderDataOnElementReducer from './reducer/setAssetProviderDataOnElementReducer';
import setAutoApplyLayoutSchemaNamesReducer from './reducer/setAutoApplyLayoutSchemaNamesReducer';
import setCollectionDisplayOptionReducer from './reducer/setCollectionDisplayOptionReducer';
import setDesignDataReducer from './reducer/setDesignDataReducer';
import setDesignSavingStatusReducer from './reducer/setDesignSavingStatusReducer';
import setGlobalPluginReducer from './reducer/setGlobalPluginReducer';
import setInnerPagesCountReducer from './reducer/setInnerPagesCountReducer';
import setInstalledPluginsReducer from './reducer/setInstalledPluginsReducer';
import setMirroringModeReducer from './reducer/setMirroringModeReducer';
import setOriginalDesignDataReducer from './reducer/setOriginalDesignDataReducer';
import setPageBackgroundColorReducer from './reducer/setPageBackgroundColorReducer';
import setSpreadBackgroundsReducer from './reducer/setSpreadBackgroundsReducer';
import setSpreadForegroundsReducer from './reducer/setSpreadForegroundsReducer';
import setSpreadLayoutSchemaNameReducer from './reducer/setSpreadLayoutSchemaNameReducer';
import setStateReducer from './reducer/setStateReducer';
import copySpreadReducer from './reducer/spreadManipulation/copySpreadReducer';
import removeSpreadReducer from './reducer/spreadManipulation/removeSpreadReducer';
import updateConditionActiveOptionReducer from './reducer/updateConditionActiveOptionReducer';
import updateConditionChildPositionReducer from './reducer/updateConditionChildPositionReducer';
import updateConditionDisplayOptionReducer from './reducer/updateConditionDisplayOptionReducer';
import updateConditionNameReducer from './reducer/updateConditionNameReducer';
import updateConditionOptionImageIdReducer from './reducer/updateConditionOptionImageIdReducer';
import updateConditionOptionLabelReducer from './reducer/updateConditionOptionLabelReducer';
import updateDigitizedElementsReducer from './reducer/updateDigitizedElementsReducer';
import updateElementsOnSpreadReducer from './reducer/updateElementsOnSpreadReducer';
import updateElementsOnSpreadsReducer from './reducer/updateElementsOnSpreadsReducer';
import updateHiddenElementsReducer from './reducer/updateHiddenElementsReducer';
import updateMediaElementReducer from './reducer/updateMediaElementReducer';
import updateMediaImageUrlReducer from './reducer/updateMediaImageUrlReducer';
import updatePersonalizationSettingsReducer from './reducer/updatePersonalizationSettingsReducer';
import updateRecoloredElementsReducer from './reducer/updateRecoloredElementsReducer';
import { DesignState } from './types';

export const designInitialState: DesignState = {
  originalDesignData: undefined,
  designData: undefined,
  innerPagesCount: undefined,
  spreadBackgrounds: [],
  digitizedAssets: {},
  designDataTouched: false,
  globalPlugins: [],
  designSavingStatus: 'saved',
};

export const slice = createSlice({
  name: 'design',
  initialState: designInitialState,
  reducers: {
    setDesignDataAction: setDesignDataReducer,
    setOriginalDesignDataAction: setOriginalDesignDataReducer,
    setStateAction: setStateReducer,

    addMediaElementAction: addMediaElementReducer,
    updateMediaElementAction: updateMediaElementReducer,
    removeMediaElementAdjustmentsAction: removeMediaElementAdjustmentsReducer,
    removeMediaElementAction: removeMediaElementReducer,
    removeAllMediaElementsFromPageAction: removeAllMediaElementsFromPageReducer,

    moveMediaElementZindexUpAction: moveMediaElementZindexUpReducer,
    moveMediaElementZindexDownAction: moveMediaElementZindexDownReducer,
    moveMediaElementZindexTopAction: moveMediaElementZindexTopReducer,
    moveMediaElementZindexBottomAction: moveMediaElementZindexBottomReducer,
    moveMediaElementZindexAction: moveMediaElementZindexReducer,

    setSpreadLayoutSchemaNameAction: setSpreadLayoutSchemaNameReducer,
    setSpreadBackgroundsAction: setSpreadBackgroundsReducer,
    setSpreadForegroundsAction: setSpreadForegroundsReducer,
    reOrderSpreadsAction: reOrderSpreadsReducer,

    setPageBackgroundColorAction: setPageBackgroundColorReducer,

    setAutoApplyLayoutSchemaNamesAction: setAutoApplyLayoutSchemaNamesReducer,

    setInnerPagesCountAction: setInnerPagesCountReducer,

    clearImageElementsByImageIdAction: clearImageElementsByImageIdReducer,
    setMirroringModeAction: setMirroringModeReducer,

    updateElementsOnSpreadAction: updateElementsOnSpreadReducer,
    updateElementsOnSpreadsAction: updateElementsOnSpreadsReducer,

    addConditionGroupAction: addConditionGroupReducer,
    addConditionToConditionAction: addConditionToConditionReducer,
    removeConditionFromConditionAction: removeConditionFromConditionReducer,
    createEmptyConditionAction: createEmptyConditionReducer,
    updateConditionNameAction: updateConditionNameReducer,
    deleteConditionAction: deleteConditionReducer,
    addConditionOptionAction: addConditionOptionReducer,
    removeConditionOptionAction: removeConditionOptionReducer,
    updateConditionOptionLabelAction: updateConditionOptionLabelReducer,
    addElementToConditionAction: addElementToConditionReducer,
    removeElementFromConditionAction: removeElementFromConditionReducer,
    updateHiddenElementsAction: updateHiddenElementsReducer,
    updateConditionDisplayOptionAction: updateConditionDisplayOptionReducer,
    updateConditionOptionImageIdAction: updateConditionOptionImageIdReducer,
    addElementToConditionGroupAction: addElementToConditionGroupReducer,
    removeElementFromGroupConditionAction: removeElementFromGroupConditionReducer,
    updateConditionChildPositionAction: updateConditionChildPositionReducer,
    setInstalledPluginsAction: setInstalledPluginsReducer,
    setCollectionDisplayOptionAction: setCollectionDisplayOptionReducer,
    updateConditionActiveOptionAction: updateConditionActiveOptionReducer,

    updateMediaImageUrlAction: updateMediaImageUrlReducer,
    setAssetProviderDataOnElementAction: setAssetProviderDataOnElementReducer,
    addDigitizedAssetAction: addDigitizedAssetReducer,
    resetDigitizedAssetAction: resetDigitizedAssetReducer,
    updateDigitizedElementsAction: updateDigitizedElementsReducer,
    updateRecoloredElementsAction: updateRecoloredElementsReducer,
    markDesignDataAsTouchedAction: markDesignDataAsTouchedReducer,

    setGlobalPluginAction: setGlobalPluginReducer,
    updatePersonalizationSettingsAction: updatePersonalizationSettingsReducer,

    copySpreadAction: copySpreadReducer,
    removeSpreadAction: removeSpreadReducer,
    setDesignSavingStatusAction: setDesignSavingStatusReducer,
  },
});

export const {
  setDesignDataAction,
  setOriginalDesignDataAction,
  setStateAction,

  addMediaElementAction,
  updateMediaElementAction,
  removeMediaElementAction,
  removeAllMediaElementsFromPageAction,

  moveMediaElementZindexUpAction,
  moveMediaElementZindexDownAction,
  moveMediaElementZindexTopAction,
  moveMediaElementZindexBottomAction,
  moveMediaElementZindexAction,

  setSpreadLayoutSchemaNameAction,
  setSpreadBackgroundsAction,
  setSpreadForegroundsAction,
  reOrderSpreadsAction,

  setAutoApplyLayoutSchemaNamesAction,

  setPageBackgroundColorAction,

  setInnerPagesCountAction,

  clearImageElementsByImageIdAction,
  setMirroringModeAction,

  updateElementsOnSpreadsAction,
  updateElementsOnSpreadAction,

  removeMediaElementAdjustmentsAction,
  addConditionGroupAction,
  addConditionToConditionAction,
  removeConditionFromConditionAction,
  updateConditionNameAction,
  deleteConditionAction,
  addConditionOptionAction,
  removeConditionOptionAction,
  updateConditionOptionLabelAction,
  addElementToConditionAction,
  removeElementFromConditionAction,
  updateHiddenElementsAction,
  updateConditionDisplayOptionAction,
  updateConditionOptionImageIdAction,
  addElementToConditionGroupAction,
  removeElementFromGroupConditionAction,
  updateConditionChildPositionAction,
  createEmptyConditionAction,
  setInstalledPluginsAction,
  setCollectionDisplayOptionAction,
  updateConditionActiveOptionAction,
  updateMediaImageUrlAction,
  setAssetProviderDataOnElementAction,
  addDigitizedAssetAction,
  resetDigitizedAssetAction,
  updateDigitizedElementsAction,
  markDesignDataAsTouchedAction,
  setGlobalPluginAction,
  updateRecoloredElementsAction,

  updatePersonalizationSettingsAction,

  copySpreadAction,
  removeSpreadAction,
  setDesignSavingStatusAction,
} = slice.actions;

export default slice.reducer;
