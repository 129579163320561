import { elementUuids } from 'editor/src/util/uuids';

import { DesignData, Spread } from '../types';

import updateDesignPagesUtil from './updateDesignPagesUtil';

function copySpreadUtil(designData: DesignData, copyFromIndex: number, positionAtIndex: number, empty: boolean) {
  const template = designData.spreads[copyFromIndex];
  const newSpread: Spread = JSON.parse(JSON.stringify(template));
  if (empty) {
    newSpread.pages.forEach((page) => {
      page.groups.media = undefined;
    });
  } else {
    newSpread.pages[0].groups.media = newSpread.pages[0].groups.media?.map((media) => {
      return {
        ...media,
        uuid: elementUuids.generate(),
      };
    });
  }
  designData.spreads.splice(positionAtIndex, 0, newSpread);
  updateDesignPagesUtil(designData);
}

export default copySpreadUtil;
