import { PayloadAction } from '@reduxjs/toolkit';

import updateDefaultSizeMeasurementValues from 'editor/src/store/design/operation/updateDefaultSizeMeasurementValues';
import { SpreadGroup } from 'editor/src/store/design/types';
import getGroupVariationByProductUid from 'editor/src/store/variants/helpers/getGroupVariationByProductUid';
import getInitialExternalOptions from 'editor/src/store/variants/helpers/getInitialExternalOptions';
import getInitialMultiOptions from 'editor/src/store/variants/helpers/getInitialMultiOptions';
import getInitialPageCount from 'editor/src/store/variants/helpers/getInitialPageCount';
import getInitialSingleOptions from 'editor/src/store/variants/helpers/getInitialSingleOptions';
import getSelectedGroupKey from 'editor/src/store/variants/helpers/getSelectedGroupKey';
import {
  ProductVariation,
  VariantsState,
  Product,
  Configuration,
  ExternalProductControl,
  VariationProductControl,
  isVariationProductControl,
  isExternalProductControl,
  ExistingVariant,
  ProductControlSize,
  ResizableElement,
  VariationGroup,
  DesignOptionControl,
} from 'editor/src/store/variants/types';

export type ExternalProduct = Omit<Product, 'productControls' | 'externalProductControls' | 'unavailableProducts'> & {
  productControls: Array<VariationProductControl | ExternalProductControl>;
  designOptionControls?: DesignOptionControl[];
  unavailableProducts?: Product['unavailableProducts'];
};

export type ProductDataPayload = {
  product: ExternalProduct;
  defaultPageCount: number | undefined;
  defaultProductUids: string[];
  defaultVariation: ProductVariation | undefined;
  defaultVariationsInfo: { [productUid: string]: { linked: boolean } } | undefined;
  selectedGroupKey: string | undefined;
  configuration: Configuration;
  existingVariants?: ExistingVariant[];
  layoutPerProductUids?: { [productUid: string]: string };
  groupedSpreadsPerProductUids?: { [productUid: string]: SpreadGroup[] };
  resizableElementPerProductUids?: { [productUid: string]: ResizableElement };
};

const setProductDataReducer = (
  state: VariantsState,
  action: PayloadAction<{
    productData: ProductDataPayload;
    updatedVariants?: ExistingVariant[];
    groups?: VariationGroup[];
  }>,
) => {
  const { productData, updatedVariants, groups } = action.payload;
  const {
    product,
    defaultVariation,
    defaultPageCount,
    defaultProductUids,
    configuration,
    selectedGroupKey,
    defaultVariationsInfo,
    existingVariants = [],
    layoutPerProductUids = {},
    groupedSpreadsPerProductUids = {},
    resizableElementPerProductUids = {},
  } = productData;

  // happens when there are no variations.
  if (!defaultVariation || !groups || !updatedVariants) {
    return;
  }

  const { designOptionControls } = product;

  const sizeControl = product.productControls?.find((control) => control.key === 'product-size') as ProductControlSize;
  if (sizeControl) {
    updateDefaultSizeMeasurementValues(sizeControl);
  }

  const productControls = product.productControls.filter(isVariationProductControl);

  const splitControlProduct: Product = {
    ...product,
    productControls,
    externalProductControls: product.productControls.filter(isExternalProductControl),
    unavailableProducts: product.unavailableProducts ?? {},
  };

  state.product = splitControlProduct;
  if (designOptionControls) {
    state.designOptionsControl = designOptionControls;
    state.designOptionsEnabled = true;
    const [firstOption] = designOptionControls[0].options;
    state.selectedDesignOptionValue = firstOption.value;
  }

  if (!state.product.controlsUIOrder) {
    state.product.controlsUIOrder = product.productControls.map((control) => control.key);
  }

  state.configuration = configuration || {};
  state.layoutPerProductUids = layoutPerProductUids;
  state.resizableElementPerProductUids = resizableElementPerProductUids;
  state.groupedSpreadsPerProductUids = groupedSpreadsPerProductUids;
  state.replaceControlKeys = product.productControls
    .filter((control) => control.type === 'single' && control.behavior === 'replace')
    .map((control) => control.key);

  existingVariants.forEach((variant) => {
    const updatedVariant = updatedVariants.find((updatedVariant) => {
      if (variant.designData?.dimensions && updatedVariant.designData?.dimensions) {
        return (
          updatedVariant.productUid === variant.productUid &&
          updatedVariant.designData.dimensions.width === variant.designData.dimensions.width &&
          updatedVariant.designData.dimensions.height === variant.designData.dimensions.height
        );
      }
      return updatedVariant.productUid === variant.productUid;
    });
    Object.assign(variant, updatedVariant);
  });

  state.isLoaded = true;
  state.selectedPageCount = getInitialPageCount(defaultPageCount, existingVariants);
  state.selectedMultiOptions = getInitialMultiOptions(
    productControls,
    product.productVariations,
    defaultProductUids,
    existingVariants,
    !!configuration.singleSelection,
  );
  state.selectedExternalOptions = getInitialExternalOptions(
    splitControlProduct.externalProductControls,
    existingVariants,
  );
  let singleControlVariation = defaultVariation;
  if (existingVariants.length) {
    singleControlVariation =
      product.productVariations.find((variation) => variation.productUid === existingVariants[0].productUid) ??
      defaultVariation;
  }
  state.selectedSingleOptions = getInitialSingleOptions(splitControlProduct.productControls, singleControlVariation);

  if (defaultVariationsInfo) {
    groups.forEach((group) => {
      group.linked = group.variationsInfo[0]
        ? !!defaultVariationsInfo[group.variationsInfo[0].variation.productUid]?.linked
        : true;
    });
  }
  state.variationGroups = groups;
  state.selectedGroupKey =
    selectedGroupKey ||
    (defaultVariation && getGroupVariationByProductUid(groups, defaultVariation.productUid))?.key ||
    getSelectedGroupKey(state.variationGroups, undefined);
};

export default setProductDataReducer;
