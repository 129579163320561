import { MediaGridInfo } from 'editor/src/store/design/types';

const createGridHash = (gridInfo: MediaGridInfo) => {
  return [
    gridInfo.designName,
    gridInfo.locale,
    gridInfo.firstDayOfWeek,
    gridInfo.hideWeekNum,
    gridInfo.gridDate.year,
    gridInfo.gridDate.month,
  ].join('-');
};

export default createGridHash;
