// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listItem--x919y{height:100%;width:100%;display:flex;justify-content:center;align-items:center;position:relative;flex-shrink:0}.listItem--x919y img{max-height:100%;max-width:80%;object-fit:contain}.loader--_nRZn svg{width:5vh;height:5vh}`, "",{"version":3,"sources":["webpack://./../editor/src/component/Preview/FlatPreview/Carousel/ListItem/index.module.scss"],"names":[],"mappings":"AAEA,iBACE,WAAA,CACA,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CACA,aAAA,CAEA,qBACE,eAAA,CACA,aAAA,CACA,kBAAA,CAKF,mBACE,SAAA,CACA,UAAA","sourcesContent":["@import 'editor/src/comonStyles/index';\n\n.listItem {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  flex-shrink: 0;\n\n  img {\n    max-height: 100%;\n    max-width: 80%;\n    object-fit: contain;\n  }\n}\n\n.loader {\n  svg {\n    width: 5vh;\n    height: 5vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": `listItem--x919y`,
	"loader": `loader--_nRZn`
};
export default ___CSS_LOADER_EXPORT___;
