import type { MediaElement } from 'editor/src/store/design/types';
import { MimeType } from 'editor/src/store/gallery/types';

export enum PluginName {
  Filters = 'app-image-filters',
  AdvancedPersonalisation = 'app-advanced-personalization',
  Graphics = 'app-graphics',
  Shutterstock = 'app-shutterstock',
  Fonts = 'app-fonts',
  TextEffects = 'app-text-effects',
  AIPersonalization = 'app-ai-personalization',
  shopifyPersonalization = 'app-shopify-personalization',
  emailWorkflowPersonalization = 'app-email-workflow-personalization',
  personalizationStudio = 'personalization-studio',
  shopifyPersonalizationInEditor = 'app-personalization-in-editor',
}

export enum ShutterstockPluginSource {
  Essentials = 'shutterstock-essentials',
  Full = 'shutterstock-full',
}

export interface PluginsState {
  list: Plugin[];
}

export enum PluginState {
  NOT_VISIBLE = 'not-visible',
  DISABLED = 'disabled',
  NON_FUNCTIONAL = 'non-functional',
  ENABLED = 'enabled',
}

export enum PremiumLevel {
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  SILVER_NEW = 'SILVER_NEW',
  GOLD_NEW = 'GOLD_NEW',
}

export enum PluginType {
  ASSETS_SOURCE = 'assets-source',
  IMAGE_MANIPULATION = 'image-manipulation',
}

export interface GelatoPromptInfo {
  description: string;
  buttonText?: string;
}

export interface Plugin {
  name: PluginName | string;
  displayName?: string;
  state: PluginState;
  indexInTabs?: number;
  indexInApps?: number;
  tab?: boolean;
  type?: PluginType;
  icon?: string;
  title?: string;
  endpoint?: string;
  info?: string;
  extra?: PluginExtra;
}

interface PluginExtra {
  premiumLevel?: PremiumLevel;
  gelatoPlusPrompt?: GelatoPromptInfo;
  hideCollections?: boolean;
  [key: string]: any;
}

export interface ImageManipulationPlugin extends Plugin {
  type: PluginType.IMAGE_MANIPULATION;
  title: string;
  icon: string;
  extra: ImageManipulationPluginExtra;
}

interface ImageManipulationPluginExtra extends PluginExtra {
  modifyImageLabel: string;
  resetImageLabel: string;
  previewModeInfo: string;
  acceptModificationLabel?: string;
  declineModificationLabel?: string;
  exceptionalImageTypes?: MimeType[];
  dimensionRestrictions?: {
    maxWidth: number;
    maxHeight: number;
    description: string;
  };
}

export enum PluginEventName {
  BeforeElementAssetChanged = 'BeforeImageChanged',
  BeforeElementAssetRemoved = 'BeforeImageRemoved',
}

export type PluginOperationParams = {
  [PluginEventName.BeforeElementAssetChanged]: [number, Partial<MediaElement>];
  [PluginEventName.BeforeElementAssetRemoved]: [string];
};

export type PluginOperationEventsConfig<E extends PluginEventName> = {
  event: E;
  operations: ((...args: PluginOperationParams[E]) => any)[];
};
