import { generateGridAsSVG, generatorUtils, GridDesign } from '@gelatoas/design-editor-calendar';

import { MediaGrid, Spread } from 'editor/src/store/design/types';

import createGridHash from '../design/createGridHash';

function updateGridElement(
  sourceElement: MediaGrid,
  destElement: MediaGrid,
  designs: GridDesign[],
  sourceSpread: Spread,
  destSpreadWith: number,
) {
  // legacy: we used to have grid on the spread
  if (!sourceElement.grid && (sourceSpread as any).grid) {
    destElement.grid = { ...(sourceSpread as any).grid };
  }
  if (!destElement.grid) {
    return;
  }

  const { locale = 'en', designName, gridDate, firstDayOfWeek, hideWeekNum } = destElement.grid;

  const design = designs.find((design) => design.name === designName) || designs[0];
  destElement.content = generateGridAsSVG(design, gridDate, locale, destElement.width, destElement.height, {
    d2f: generatorUtils.getToSizeFn(design, destSpreadWith),
    firstDayOfWeek,
    hideWeekNum,
  });
  destElement.gridHash = createGridHash(destElement.grid);
}

export default updateGridElement;
