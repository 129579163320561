import { LayoutGroup } from 'editor/src/store/editorModules/layouts/types';

export const GROUP_LAYOUT_TAG_CALENDAR = 'calendar';

const layouts = [
  { layouts: ['grid-empty', 'grid-empty'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', '1'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', '2'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i1t1_3'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', '3'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  {
    layouts: ['grid-vertical-4', 'grid-empty'],
    tag: GROUP_LAYOUT_TAG_CALENDAR,
  },
  {
    layouts: ['grid-vertical-0', 'grid-empty'],
    tag: GROUP_LAYOUT_TAG_CALENDAR,
  },
  {
    layouts: ['grid-vertical-1', 'grid-empty'],
    tag: GROUP_LAYOUT_TAG_CALENDAR,
  },
  {
    layouts: ['grid-vertical-2', 'grid-empty'],
    tag: GROUP_LAYOUT_TAG_CALENDAR,
  },
  {
    layouts: ['grid-vertical-3', 'grid-empty'],
    tag: GROUP_LAYOUT_TAG_CALENDAR,
  },
];

const groupLayouts: LayoutGroup[] = layouts.map(({ layouts, tag }) => ({
  layouts,
  tag,
  name: layouts.join('_'),
}));

export default groupLayouts;
