import { fabric } from 'fabric';
import React, { useMemo } from 'react';

import { Bleed, Content, Coords, MediaBox } from 'editor/src/store/design/types';
import getSelectedElementUuids from 'editor/src/store/editor/selector/getSelectedElementUuids';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import { SettingsProperty } from 'editor/src/store/editor/types';
import { useSelector } from 'editor/src/store/hooks';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricPolygonComponent from 'editor/src/component/EditorArea/fabricComponents/FabricPolygonComponent';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import getSpreadBackgroundPolygons from './getSpreadBackgroundPolygons';
import getTrimClipPath from './getTrimClipPath';

interface Props {
  contents: Content[] | undefined;
  bleeds: Bleed[] | undefined;
  mediabox: MediaBox[] | undefined;
  focusedContent: Content | undefined;
  pageCoords: Coords;
  canvasRotation: CanvasRotation;
  backgroundColor?: string;
}

export const SHADOW = new fabric.Shadow({
  color: 'rgba(0, 0, 0, 0.4)',
  blur: 5,
  offsetX: 0,
  offsetY: 3,
  affectStroke: false,
});

export const DEFAULT_PAGE_COLOR = 'rgba(255, 255, 255, 1)';

function SpreadBackgroundColor({
  contents,
  focusedContent,
  bleeds,
  mediabox,
  pageCoords,
  canvasRotation,
  backgroundColor,
}: Props) {
  const { mm2px } = useFabricUtils();
  const showBleeds = useSelector((state) => getSettingsValue(state, SettingsProperty.showBleeds));
  const anyElementSelected = useSelector((state) => getSelectedElementUuids(state).length > 0);
  const hasRepeat = useSelector((state) => !!state.hostSettings.repeatDesign);
  const fullBleeds = showBleeds || anyElementSelected;

  const backgroundPolygon = useMemo(
    () =>
      getSpreadBackgroundPolygons(
        mediabox || [],
        contents || [],
        bleeds || [],
        focusedContent,
        pageCoords,
        canvasRotation,
        mm2px,
        fullBleeds,
      ),
    [contents, bleeds, mediabox, focusedContent, pageCoords, mm2px, canvasRotation, fullBleeds],
  );

  const trimClipPath = useMemo(
    () => getTrimClipPath(bleeds, mm2px, fullBleeds, pageCoords),
    [bleeds, mm2px, pageCoords, fullBleeds],
  );

  return (
    <>
      {backgroundPolygon.map((polygon, i) => (
        <FabricPolygonComponent
          key={i}
          polygon={polygon}
          fill={backgroundColor || DEFAULT_PAGE_COLOR}
          evented={false}
          shadow={hasRepeat ? undefined : SHADOW}
          zIndex={zIndex.BACKGROUND_COLOR}
          objectCaching={false}
          clipPath={trimClipPath}
        />
      ))}
    </>
  );
}

export default React.memo(SpreadBackgroundColor);
